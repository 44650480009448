import { useEffect, useState, useRef, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { WithHeader } from '@components/templates/mobile/WithHeader';
import * as S from './ProductsForStocks.styles';
import { ReactComponent as FactoryIcon } from '@assets/icons/mobile/factory.svg';
import { ReactComponent as ExpandMore } from '@assets/icons/expand_more.svg';
import SearchConditionList from '@components/modules/common/mobile/SearchConditionList/SearchConditionList';
import ProductList from '@components/modules/mobile/products_for_stocks/ProductList/ProductList';
import SelectedTagList from '@components/modules/common/mobile/SelectedTagList/SelectedTagList';
import useTags from '@lib/mobile/common/hooks/useTags';
import useStorerooms from '@lib/mobile/common/hooks/useStorerooms';
import useProducts from '@lib/mobile/common/hooks/useProducts';
import TagsForStocksModal from '@components/modules/mobile/tags_for_stocks_modal/TagsForStocksModal/TagsForStocksModal';
import StoreroomsForStocksModal from '@components/modules/mobile/storerooms_for_stocks_modal/StoreroomsForStocksModal/StoreroomsForStocksModal';
import FloatingCameraButton from '@components/modules/common/mobile/FloatingCameraButton/FloatingCameraButton';
import {
  MATERIAL_PRODUCT_KIND,
  PRODUCT_KIND,
  type Product,
  type SearchState,
} from '@lib/common/type';
import { useWindowScroll } from 'react-use';
import type { Storeroom, Todo } from '@lib/common/type';
import BarcodeReader from '../RealtimeScanner/BarcodeReader';
import scanSound from '@assets/sounds/sound.mp3';
import moment from 'moment';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import InfiniteLoader from 'react-window-infinite-loader';
import { axiosInstance } from '@lib/mobile/common/api/axiosConfig';
import useSWR, { useSWRConfig } from 'swr';
import humps from 'humps';
import { CircularProgress } from '@material-ui/core';

export const ProductsForStocksRoot = () => {
  const history = useHistory();
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>([]);
  const [selectedStoreroomId, setSelectedStoreroomId] = useState<number | ''>(
    ''
  );
  const [scanning, setScanning] = useState(true);
  const [results, setResults] = useState<Todo>([]);
  const [inputText] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [audio] = useState(new Audio(scanSound));
  const [selectedStoreroomName, setSelectedStoreroomName] = useState<
    string | ``
  >('');
  // 在庫室検索文字列
  const [searchStoreroom, setSearchStoreroom] = useState('');
  const [filteredStoreroom, setFilteredStoreroom] = useState<Storeroom[]>([]);
  const { state } = useLocation<SearchState>();
  const { data: tags } = useTags(
    MATERIAL_PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT
  );
  const { data: storerooms } = useStorerooms(
    MATERIAL_PRODUCT_KIND.PRODUCT_AND_SEMI_PRODUCT
  );
  const { data: products } = useProducts();

  // タグmodal画面open用
  const [tagsOpen, setTagsOpen] = useState(false);
  // 在庫室modal画面open用
  const [storeroomOpen, setStoreroomOpen] = useState(false);

  // 検索文字state
  const [searchChar, setSearchChar] = useState('');
  // スクロール位置state
  const { y: currentScrollPosition } = useWindowScroll();
  const [scrollPosition, setScrollPosition] = useState(0);
  // products一覧state
  const [stateProductsData, setStateProductsData] = useState<Product[]>([]);
  const [stateStocksData, setStateStocksData] = useState<Product[]>([]);

  const timerRef = useRef<NodeJS.Timeout | null>(null);
  // 検索➜在庫入力➜検索結果状態を取得する
  useEffect(() => {
    if (state === undefined) {
      setSearchChar('');
      setSelectedTagIds([]);
      setSelectedStoreroomName('');
      setSelectedStoreroomId('');
      setStateProductsData([]);
      setStateStocksData([]);
    } else {
      const {
        stateSearchChar,
        stateSelectedTagIds,
        stateSelectedStoreroom,
        stateSelectedStoreroomId,
        stateScrollPosition,
        stateProductsData,
        stateStocksData,
      } = state;
      window.scrollTo(0, stateScrollPosition);
      setSearchChar(stateSearchChar);
      setSelectedTagIds(stateSelectedTagIds);
      setSelectedStoreroomName(stateSelectedStoreroom);
      setSelectedStoreroomId(stateSelectedStoreroomId);
      setStateProductsData(stateProductsData);
      setStateStocksData(stateStocksData);
      setScrollPosition(stateScrollPosition);
    }
    products && setStateProductsData(products);
  }, []);

  const searchState = {
    stateSearchChar: searchChar,
    stateSelectedTagIds: selectedTagIds,
    stateSelectedStoreroom: selectedStoreroomName,
    stateSelectedStoreroomId: selectedStoreroomId,
    stateScrollPosition: currentScrollPosition,
    stateProductsData: stateProductsData,
    stateStocksData: stateStocksData,
  };

  useEffect(() => {
    let combinedDataFromStocks: Todo[] = [];
    if (selectedStoreroomId && products) {
      const filteredFromStocks = products.filter((product) =>
        product.storeroomIds.includes(selectedStoreroomId)
      );
      combinedDataFromStocks = [...filteredFromStocks];
    }
    if (results.length !== 0) {
      const getData = [
        ...searchState.stateProductsData,
        ...combinedDataFromStocks,
      ].filter(
        (product: Todo) =>
          (product.janCode === results[0]?.codeResult?.code &&
            product.storeroomIds.includes(Number(selectedStoreroomId))) ||
          (product.productCode === results[0].codeResult.code &&
            product.storeroomIds.includes(Number(selectedStoreroomId)))
      );
      if (
        !!getData &&
        getData.length > 0 &&
        getData[0].productKind === PRODUCT_KIND.PRODUCT
      ) {
        // 外部バーコード読取時のみ、localStorageへtrueをSet
        localStorage.setItem('useScanning', 'true');
        history.push(
          `/mobile/products/${getData[0].id}/stocks/${
            PRODUCT_KIND.PRODUCT
          }/${moment().format('YYYY-MM-DD')}/${selectedStoreroomId}/edit`,
          searchState
        );
      } else if (
        !!getData &&
        getData.length > 0 &&
        getData[0].productKind === PRODUCT_KIND.SEMI_PRODUCT
      ) {
        history.push(
          `/mobile/products/${getData[0].id}/stocks/${
            PRODUCT_KIND.SEMI_PRODUCT
          }/${moment().format('YYYY-MM-DD')}/${selectedStoreroomId}/edit`,
          searchState
        );
      } else {
        // 該当のjanCodeがなかった場合
        setErrMsg('該当の商品が見つかりませんでした。');
      }
    }
  }, [results]);

  const selectedTags =
    tags?.filter((tag) => selectedTagIds.includes(tag.id)) || [];

  // 場所検索機能
  useEffect(() => {
    if (!storerooms) return;
    if (searchStoreroom) {
      setFilteredStoreroom(
        storerooms.filter((sr) => sr.name.match(searchStoreroom))
      );
    } else {
      setFilteredStoreroom(storerooms);
    }
  }, [searchStoreroom, storerooms]);

  // 初期の在庫室選択画面表示時、localStorageからuseScanning削除
  useEffect(() => {
    if (state || selectedStoreroomId) return;
    localStorage.removeItem('useScanning');
  }, [state]);

  useEffect(() => {
    if (state === undefined) {
      window.scrollTo(0, 0);
    } else {
      window.scrollTo(0, state.stateScrollPosition);
      window.scrollTo(0, scrollPosition);
    }
  }, [scrollPosition]);

  const [hasNextPage, setHasNextPage] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [metaCurrentPage, setMetaCurrentPage] = useState(0);
  const [metaTotalPages, setMetaTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { mutate } = useSWRConfig();
  const { data: filteredProducts } = useSWR('filteredProducts');
  const isInitialLoadComplete = useRef(false);

  const fetchData = useCallback(
    async (currentPage: number) => {
      try {
        const response = await axiosInstance().get(
          `/api/v1/stocks`, // Adjust the endpoint as needed
          {
            params: {
              date: moment().format('YYYYMMDD'),
              storeroom_id: selectedStoreroomId,
              page: currentPage + 1,
              search: searchChar,
              tag_ids: selectedTags.map((item: Todo) => item['id']),
              per_page: 50,
            },
          }
        );
        const newItems = humps.camelizeKeys(response?.data?.products);
        setMetaCurrentPage(response?.data?.meta.current_page);
        setMetaTotalPages(response?.data?.meta.total_pages);

        // Store filteredProducts in SWR global state
        mutate('filteredProducts', newItems, false);

        return newItems;
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [searchChar, selectedTagIds, selectedStoreroomId]
  );

  const loadMoreItems = useCallback(async () => {
    if (!isInitialLoadComplete.current) return;
    if (isNextPageLoading || !hasNextPage) return;

    setIsNextPageLoading(true);
    if (metaCurrentPage < metaTotalPages) {
      const newProducts = await fetchData(currentPage);

      if (!newProducts?.length) {
        setHasNextPage(false);
      } else {
        mutate(
          'filteredProducts',
          [...(filteredProducts || []), ...newProducts],
          false
        );
        setCurrentPage((prev) => prev + 1);
      }
    }
    setIsNextPageLoading(false);
  }, [
    currentPage,
    hasNextPage,
    isNextPageLoading,
    metaCurrentPage,
    metaTotalPages,
    filteredProducts,
    fetchData,
  ]);

  const isItemLoaded = (index: number) =>
    index < (filteredProducts || []).length;

  const clearAll = () => {
    setHasNextPage(true);
    setIsNextPageLoading(false);
    setCurrentPage(0);
    mutate('filteredProducts', [], false);
  };

  const fetchFilteredProducts = async () => {
    fetchData(0);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (selectedStoreroomId) {
      fetchFilteredProducts();
      setTimeout(() => {
        isInitialLoadComplete.current = true;
      }, 1500);
    }
  }, [selectedStoreroomId]);

  useEffect(() => {
    if (!isInitialLoadComplete.current) return;

    setIsLoading(true);
    clearAll();
    fetchFilteredProducts();
  }, [selectedTagIds]);

  useEffect(() => {
    if (!isInitialLoadComplete.current) return;

    setIsLoading(true);
    clearAll();
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(() => {
      fetchFilteredProducts();
    }, 2000);

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [searchChar]);

  if (tagsOpen) {
    return (
      <TagsForStocksModal
        setTagsOpen={setTagsOpen}
        selectedTagIds={selectedTagIds}
        setSelectedTagIds={setSelectedTagIds}
        fromComponent={'productsForStocks'}
      />
    );
  }

  if (storeroomOpen) {
    return (
      <StoreroomsForStocksModal
        searchStoreroom={searchStoreroom}
        setSearchStoreroom={setSearchStoreroom}
        storerooms={filteredStoreroom}
        setStoreroomOpen={setStoreroomOpen}
        setSelectedStoreroomId={setSelectedStoreroomId}
        setSelectedStoreroomName={setSelectedStoreroomName}
      />
    );
  }

  return (
    <WithHeader>
      <S.Wrapper>
        <S.Title>
          <S.TitleText>在庫の入力</S.TitleText>
          <S.StoreroomPanel onClick={() => setStoreroomOpen(true)}>
            <FactoryIcon />
            {selectedStoreroomName ? (
              <S.StorageText>{selectedStoreroomName}</S.StorageText>
            ) : (
              <S.StorageText>場所を選択</S.StorageText>
            )}
            <S.ExpandMore>
              <ExpandMore />
            </S.ExpandMore>
          </S.StoreroomPanel>
        </S.Title>
        {selectedStoreroomId && (
          <S.SearchConditionList>
            {selectedTags.length === 0 ? (
              <SearchConditionList
                setTagsOpen={setTagsOpen}
                searchChar={searchChar}
                setSearchChar={setSearchChar}
              />
            ) : (
              <SelectedTagList
                tags={selectedTags}
                clearAll={() => setSelectedTagIds([])}
                clear={(tagId) =>
                  setSelectedTagIds(selectedTagIds.filter((id) => id !== tagId))
                }
                tagsOpen={tagsOpen}
                setTagsOpen={setTagsOpen}
                clearTagSearch={() => {
                  setSelectedTagIds([]);
                }}
              />
            )}
          </S.SearchConditionList>
        )}
        <S.ProductList id="product-list">
          {filteredProducts === undefined ? (
            <S.SelectStoreroomText>
              場所を選択してください。
            </S.SelectStoreroomText>
          ) : !isLoading &&
            (searchChar !== '' || selectedTagIds.length > 0) &&
            filteredProducts.length === 0 ? (
            <S.NoProductsToShow>
              製品が見つかりませんでした。
              <div>条件を変更して再度検索してください。</div>
            </S.NoProductsToShow>
          ) : filteredProducts?.length === 0 && selectedStoreroomId ? (
            <S.SelectStoreroomText>
              <S.CenteredCircularProgress>
                <CircularProgress />
              </S.CenteredCircularProgress>
            </S.SelectStoreroomText>
          ) : (
            <>
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={
                  hasNextPage
                    ? (filteredProducts?.length || 0) + 1
                    : filteredProducts?.length || 0
                }
                loadMoreItems={loadMoreItems}
                threshold={15}
              >
                {({ onItemsRendered, ref }) => (
                  <S.StyledFixedSizeList
                    height={800}
                    itemCount={filteredProducts?.length || 0}
                    itemSize={60}
                    width="100%"
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                  >
                    {({ index, style }) => (
                      <div style={style}>
                        <ProductList
                          products={[filteredProducts[index]]}
                          searchChar={searchChar}
                          selectedTagIds={selectedTagIds}
                          selectedStoreroom={selectedStoreroomName}
                          selectedStoreroomId={selectedStoreroomId}
                          scrollPosition={currentScrollPosition}
                        />
                      </div>
                    )}
                  </S.StyledFixedSizeList>
                )}
              </InfiniteLoader>
              {scanning ? (
                <BarcodeReader
                  onDetected={(result: Todo) => {
                    setScanning(false);
                    audio.play();
                    const newResult = {
                      codeResult: { code: result.codeResult.code },
                      inputText,
                    };
                    setResults([...results, newResult]);
                  }}
                />
              ) : (
                <ErrorMsgPopUp
                  fromPc={true}
                  errMsg={errMsg}
                  handleClose={() => {
                    setResults([]);
                    setScanning(true);
                    setErrMsg('');
                  }}
                />
              )}
            </>
          )}
        </S.ProductList>
      </S.Wrapper>
      {selectedStoreroomId && filteredProducts?.length > 0 && (
        <FloatingCameraButton
          handleClick={() =>
            history.push(`/mobile/live/${selectedStoreroomId}`, searchState)
          }
        />
      )}
    </WithHeader>
  );
};
export default ProductsForStocksRoot;
